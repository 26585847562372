import React from "react";
import { Layout, Newsletter }  from "@startuponsale/gatsby-shared-ui";

const NewsletterPage = () => {
  return (
    <div>
      <Layout>
        <Newsletter />
      </Layout>
    </div>
  );
};

export default NewsletterPage;
